import React from "react";
import { Layout } from "../../components/Layout";
import { Container } from "../../components/Section";
import { Seo } from "../../components/Seo";
import { getPrivacyUrl } from "../../utils/urls";

const Content: React.FC = () => (
  <>
    <h1>monday.com Apps End User License Agreement</h1>

    <p>
      <strong>Last updated Aug 19, 2024</strong>
    </p>
    <p>
      IMPORTANT – READ CAREFULLY: The END USER LICENSE AGREEMENT (hereinafter
      referred to as EULA) is a legal agreement between you (either an
      individual or a single entity) and LeanyLabs OÜ (hereinafter referred to
      as LEANYLABS) for the "Mirror Column Automations", "Search in Updates",
      "Google Sheets Integration", "Google Docs Integration", "DocX Templater",
      and "Org Chart" monday.com Apps(hereinafter referred to as SOFTWARE). By
      installing, copying, or otherwise using the SOFTWARE, you agree to be
      bound by the terms of this EULA.
    </p>
    <p>
      This EULA is to be read in conjunction with monday.com “Marketplace
      listings Terms” available at{" "}
      <a href="https://monday.com/l/legal/monday-com-marketplace-listing-terms/">
        https://monday.com/l/legal/monday-com-marketplace-listing-terms/
      </a>
      . In case of any conflict between the terms of this License and monday.com
      Marketplace Terms of Service, the former shall prevail.
    </p>
    <h2>1. Grant of License</h2>

    <p>
      1.1. The SOFTWARE is protected by copyright laws and international
      copyright treaties, as well as other intellectual property laws and
      treaties.
    </p>
    <p>
      1.2. This license grants you the limited, worldwide, non-exclusive right
      to install and use the SOFTWARE for the number of licensed users.
    </p>
    <p>
      1.3. You are allowed to copy the software for backup, archival as well as
      testing purposes.
    </p>
    <p>
      1.4. You may not sell, transfer or convey the Software to any third party
      without LEANYLABS prior express written consent.
    </p>
    <h2>2. Ownership and Reservation of Rights</h2>

    <p>
      2.1. All rights of any kind, which are not expressly granted by the
      present EULA, are entirely and exclusively reserved to and by LEANYLABS.
    </p>
    <p>
      2.2. The Software is licensed, not sold. You do not acquire any ownership
      rights as a result of downloading, installing or using the SOFTWARE.
    </p>
    <h2>3. Termination</h2>

    <p>
      3.1. You may terminate this EULA at any time by destroying all your copies
      of the Software.
    </p>
    <p>
      3.2. The license will automatically terminate if you fail to comply with
      the terms of this agreement.
    </p>
    <p>
      3.3. On termination, you are required to remove the SOFTWARE from your
      computer and destroy all copies of the Software and Documentation and all
      its component parts.
    </p>
    <p>
      3.4. There shall be no refund or adjustment for amounts paid by you to
      LEANYLABS.
    </p>
    <p>
      3.5. The provisions of the present EULA which by their nature extend
      beyond the termination date of the EULA will survive and remain in effect
      and enforceable until all obligations are fully satisfied.
    </p>
    <h2>4. Infringement Indemnification</h2>

    <p>
      4.1. LEANYLABS will hold YOU harmless, defend and indemnify You, against a
      third party claim to the extent based on an allegation that the SOFTWARE
      infringes a third party intellectual property right, provided that
      LEANYLABS: (a) is promptly notified and furnished a copy of such Claim,
      and all other documents that the claim is based on (b) is given reasonable
      assistance in and sole control of the defense thereof and all negotiations
      for its settlement.
    </p>
    <p>
      4.2. If the SOFTWARE becomes, or in the opinion of LEANYLABS may become,
      the subject of a Claim, LEANYLABS may, at its option and in its
      discretion: (a) procure for YOU the right to use the SOFTWARE, free of any
      liability; (b) replace or modify the SOFTWARE to make it non-infringing;
      or (c) terminate your right to continue using the SOFTWARE and refund, in
      this case, any license fees related to the SOFTWARE paid by YOU.
    </p>
    <h2>5. Limitation of Liability</h2>

    <p>
      5.1. Except for the indemnification clause, neither party will be liable
      to any person for the use of the SOFTWARE.
    </p>
    <p>
      5.2. In no event will the total liability under any claims arising out of
      this agreement exceed the license fees paid under this agreement
    </p>
    <h2>6. Governing Law</h2>

    <p>
      6.1. This EULA is governed by the law of Estonia excluding its conflicts
      of law rules.
    </p>

    <h2>7. Privacy and Other Policies</h2>
    <p>
      7.1. LEANYLABS will collect and/or process personal data according to its
      Privacy Policy (
      <strong>
        &nbsp;
        <a
          href={`https://leanylabs.com${getPrivacyUrl()}`}
          target="_blank"
          rel="noreferrer"
        >
          https://leanylabs.com{getPrivacyUrl()}
        </a>
      </strong>
      ). Additionally, You understand and agree that LEANYLABS may contact You
      via e-mail or otherwise with information relevant to Your use of the
      SOFTWARE, regardless of whether You have opted out of receiving marketing
      communications or notices.
    </p>

    <h2>8. Entire Agreement</h2>

    <p>
      8.1. This EULA represents the complete agreement concerning this license
      between the parties and supersedes all prior agreements and
      representations between them.
    </p>
    <p>
      8.2. LEANYLABS may amend or modify this EULA from time to time. Users can
      request to be informed of any changes by submitting a written request via
      email to support@leanylabs.com.
    </p>
    <p>
      8.3. Unless required by Laws, we agree not to make modifications that
      would substantially diminish our obligations during your then-current
      License Term.
    </p>
    <p>
      8.4. For our free software, you must accept the modifications to continue
      using the software. If you object to the modifications, your exclusive
      remedy is to stop using the free software.
    </p>
    <p>
      8.5. For our paid software, customers can raise their objection by
      providing notice to support@leanylabs.com within thirty (30) days of us
      providing notice of the modification. If the resolution is unacceptable,
      the customer can request for termination and refund for any fees prepaid
      for use of the affected Software for the terminated portion of the License
      Term.
    </p>
    <p>
      8.6. For avoidance of doubt, any Order is subjected to the version of the
      Agreement in effect at the time of the Order.
    </p>
    <p>
      8.7. If any provision of this EULA is held to be unenforceable for any
      reason, such provision shall be reformed only to the extent necessary to
      make it enforceable and the remaining provisions of this EULA shall remain
      in full force and effect.
    </p>
  </>
);

const Page: React.FC = () => (
  <Layout>
    <Seo
      ogFullTitle
      title="monday.com Apps End User Licence Agreement"
      description="Please make sure you understand our End User Licence Agreement before using our apps."
    />
    <Container>
      <Content />
    </Container>
  </Layout>
);

export default Page;
